<template>
  <v-card>
    <v-card-title class="HeaderTitle">
      <v-toolbar color="primary" density="compact" flat>
        <v-toolbar-title class="textcolor">
          Neue Passwort Eingabe
        </v-toolbar-title>
      </v-toolbar>
    </v-card-title>

    <v-card-text>
      <v-form ref="PasswordCheck">
        <v-text-field
          class="mt-4 mr-2 ml-2"
          v-model="Password"
          label="Neues Passwort"
          type="password"
          color="primary"
          density="compact"
          variant="outlined"
          :rules="[rules.required, rules.laenge, rules.Sonderzeichen]"
        >
        </v-text-field>

        <v-text-field
          class="ma-2"
          v-model="PasswordRepeat"
          label="Neues Passwort akzeptieren"
          type="password"
          color="primary"
          density="compact"
          variant="outlined"
          :error="ShowPasswordhint"
          :persistent-hint="ShowPasswordhint"
          :hint="hinttext"
        >
        </v-text-field>
      </v-form>
      <v-btn
        class="ma-2"
        align="center"
        justify="center"
        @click="saveNewPassword()"
      >
        Senden
      </v-btn>
    </v-card-text>
  </v-card>

  <v-dialog v-model="showErfolgDialog">
    <v-card>
      <v-card-title class="HeaderTitle">
        <v-toolbar color="primary" density="compact" flat>
          <v-toolbar-title class="textcolor"> Erfolg </v-toolbar-title>
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        <v-row class="ma-2" align="center" justify="center">
          <v-icon color="green" size="100"> mdi-check-circle </v-icon>
        </v-row>
        <v-row align="center" justify="center" class="ma-2">
          Passwort Erfogreich geändert
        </v-row>
        <v-row align="center" justify="center">
          <v-btn class="ma-2" @click="ReturnToLogin()"> ok </v-btn></v-row
        >
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog v-model="Fehler"> <Errors /> </v-dialog>
</template> 

<script setup>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import dbhelper from "@/plugins/dbHelper";
import Error from "@/components/main/Errors.vue";
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();

const store = useStore();

var Fehler = ref(false);

var showErfolgDialog = ref(false);
var ShowPasswordhint = ref(false);

var Password = ref("");
var PasswordRepeat = ref("");
var Erfolg = ref(false);
var PasswordCheck = ref(false);
var hinttext = ref("");

var rules = ref({
  required: (value) => !!value || "Pflichtfeld",
  laenge: (value) => (value && value.length > 6) || "Mindestens 6 Zeichen",
  Sonderzeichen: (value) =>
    (value && /[^A-Za-z0-9]/.test(value)) ||
    "Mindestens ein Sonderzeichen muss enthalten sein",
});

onMounted(async () => {});

async function saveNewPassword() {
  var url = "ResetPassword";
  if (Password.value == PasswordRepeat.value) {
    ShowPasswordhint.value = false;
    hinttext.value = "";

    let checkPassswordtest = await PasswordCheck.value.validate();
    if (checkPassswordtest.valid == true) {
      var response = await dbhelper.ResetPassword(
        url,
        store.state.ResetToken,
        Password.value
      );
      if (response.data.erfolgJN == "J") {
        showErfolgDialog.value = true;
      } else {
        store.state.FehlerCode = 202;
        store.state.FehlerText = response.result.fehlertext;
        Fehler.value = true;
      }
    }
  } else {
    ShowPasswordhint.value = true;
    hinttext.value = "Passwörter sind nicht identisch!";
  }
}

async function ReturnToLogin() {
  store.state.Login = false;
  store.state.PasswordResetPage = false;
  store.state.VerwaltungEinschalten = true;
  router.go("/reservierung/Default");
  
}
</script>

