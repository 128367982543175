<template>
  <v-card>
    <v-card-title class="HeaderTitle">
      <v-toolbar density="compact" color="primary">
        <v-toolbar-title> Passwort Zurücksetzen</v-toolbar-title>
      </v-toolbar>
    </v-card-title>
    <v-card-text>
      Möchten sie ihr Passwort zurücksetzen? Geben Sie die von ihnen hinterlegte
      E-Mail ein.
      <v-text-field
        class="ma-2"
        v-model="EMail"
        label="E-mail"
        density="compact"
        variant="outlined"
      >
      </v-text-field>

      <v-btn @click="sendResetEMail()"> Senden </v-btn>
    </v-card-text>
  </v-card>

  <v-dialog v-model="showSendErfolg">
    <v-card>
      <v-card-title class="HeaderTitle">
        <v-toolbar density="compact" color="primary">
          <v-toolbar-title>E-Mail wurde versendet </v-toolbar-title>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-col>
          <v-row class="ma-2" align="center" justify="center">
            <v-icon color="green" size="100"> mdi-check-circle </v-icon>
          </v-row>

          <v-row align="center" justify="center" class="ma-2">
            Eine E-Mail wurde an die angegebene E-Mail
            versendet, mit Informationen wie sie ihr Passwort zurücksetzen können.
          </v-row>
          <v-row align="center" justify="center" class="ma-1">
            <v-btn @click="closeErfolg()">ok</v-btn>
          </v-row>
        </v-col>
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog v-model="Fehler"><Errors /></v-dialog>
</template>

<script setup>
import Errors from "@/components/main/Errors.vue";
import { ref } from "vue";
import { useStore } from "vuex";
import dbhelper from "@/plugins/dbHelper";

const emit = defineEmits(["closeSendPasswordVergessen"]);

const store = useStore();

var Fehler = ref(false);
var showSendErfolg = ref(false);

var EMail = ref("");

async function sendResetEMail() {
  var url = "ResetPasswordEMail";
  var response = await dbhelper.sendResetEMail(url, EMail.value);
  if (response.data.erfolgJN == "J") {
    if (response.data.hinweistext == "send") {
      showSendErfolg.value = true;
    } else {
      store.state.FehlerCode = 100;
      Fehler.value = true;
    }
  } else {
    store.state.FehlerCode = response.FehlerCode;
    Fehler.value = true;
  }
}

function closeErfolg() {
  showSendErfolg.value = false;
  emit("closeSendPasswordVergessen");
}
</script>