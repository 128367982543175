<template>
  <v-spacer></v-spacer>
  <v-col align-self="auto">
    <v-row>
      <v-progress-circular color="primary" indeterminate :size="100" :width="8">
        {{ "Lade.." }}
      </v-progress-circular>
    </v-row>

    <v-row>
      <v-label class="ma-1"> Bitte Warten</v-label>
    </v-row>
  </v-col>
</template>