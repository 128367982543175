<template>
    <v-card>
      <v-alert
        v-if="FehlerCode == 202"
        color="red"
        :title="Fehler"
        icon="$warning"
        :text="FehlerText"
      >
      </v-alert>
  
      <v-alert
        v-if="FehlerCode == 100"
        color="red"
        title="Error 100"
        icon="$warning"
        text="Ungültige E-Mail Adresse."
      >
      </v-alert>
  
      <v-alert
        v-if="FehlerCode == 101"
        color="red"
        title="Error 101"
        icon="$warning"
        text="Code nicht Richtig"
      >
      </v-alert>
  
      <v-alert
        v-if="FehlerCode == 251"
        color="red"
        title="Passwort nicht gleich"
        icon="$warning"
        text="Passwort nicht gleich. Bitte überprüfen sie ihre Eingaben."
      >
      </v-alert>
  
      <v-alert
        v-if="FehlerCode == 500"
        color="red"
        icon="$warning"
        title="Error 500"
        text="Server interner Fehler"
      >
      </v-alert>
  
      <v-alert
        v-if="FehlerCode == 404"
        color="red"
        title="Error 404"
        icon="$warning"
        text=" Keine verbindung zum Server. Bitte überprüfen sie on sie mit dem Internet verbunden sind"
      >
      </v-alert>
  
      <v-alert
        v-if="FehlerCode == 400"
        color="red"
        title="Error 400"
        icon="$warning"
        text=" Bad Request"
      >
      </v-alert>
  
      <v-alert
        v-if="FehlerCode == 401"
        color="red"
        title="Error 401"
        icon="$warning"
        text="Unbekannter Fehler "
      >
      </v-alert>
    </v-card>
  </template>
  
  <script setup>
  import { ref, onMounted } from "vue";
  import { useStore } from "vuex";
  
  const store = useStore();
  var FehlerTitle = ref("");
  var FehlerCode = ref(0);
  var FehlerText = ref("");
  
  onMounted(() => {
    FehlerCode.value = store.state.FehlerCode;
    FehlerText.value = store.state.FehlerText;
    FehlerTitle.value = store.state.FehlerTitle;
  });
  </script>
  