<template>
  <v-col>
    <v-tabs
      ><v-tab @click="Page = 0"> Ihre Daten </v-tab>
      <v-tab @click="Page = 1">Account Einstellungen</v-tab>
    </v-tabs>

    <v-row v-if="Page == 0">
      <BenutzerdatenAccount />
    </v-row>

    <v-row v-if="Page == 1">
      <AccountDetail />
    </v-row>
  </v-col>
</template>

<script setup>
import AccountDetail from "./AccountDetail.vue";
import BenutzerdatenAccount from "./BenutzerdatenAccount.vue";

import { ref } from "vue";


var Page = ref(0);
</script>
