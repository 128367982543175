<template>
  <!-- Diese Komponente git eine card aus mit möglichkeiten die ein Benutzer machen kann
  -- 1. Dokumentuploaden
  -- 2. Dokument auswählen welches geuploaded werden soll
  -->
  <v-card class="Optionen" variant="outlined">
    <v-card-title class="HeaderTitle"
      ><v-toolbar color="primary" density="compact">
        <v-toolbar-title> Datei hochladen</v-toolbar-title>
      </v-toolbar>
    </v-card-title>

    <v-card-text>
      <v-form ref="Bezeichnung">
        <v-text-field
          class="mt-4"
          color="primary"
          v-model="DatenPacket.Bezeichnung"
          label="Bezeichnung"
          density="compact"
          variant="outlined"
          :rules="[rules.required]"
        ></v-text-field>
      </v-form>
      <v-combobox
        class="mt-2"
        color="primary"
        label="Art"
        v-model="DatenPacket.Art"
        :items="ArtItems"
        density="compact"
        variant="outlined"
      >
      </v-combobox>

      <v-row class="mt-2 hidden-xs">
        <v-file-input
          class="ma-2"
          v-model="DatenPacket.PDFFile"
          color="primary"
          accept=".png, .jpg, .jpeg, .pdf"
          label="Datei auswählen"
          prepend-icon="mdi-file-upload"
          variant="outlined"
          density="compact"
        ></v-file-input>

        <v-btn class="ma-2 mt-2" @click="sendfile()"> hochladen </v-btn>
      </v-row>

      <v-file-input
        class="ma-2 hidden-sm-and-up"
        v-model="DatenPacket.PDFFile"
        color="primary"
        accept=".png, .jpg, .jpeg, .pdf"
        label="Datei auswählen"
        prepend-icon="mdi-file-upload"
        variant="outlined"
        density="compact"
      ></v-file-input>

      <v-alert
        text="Maximale Größe 1MB"
        icon="$info"
        color="blue"
        variant="outlined"
        density="compact"
      ></v-alert>

      <v-dialog v-model="SizeAlert">
        <v-alert
          title="Maximale Dateigröße überschritten"
          icon="mdi-alert"
          color="warning"
          text="Die Datei ist zu groß! Maximal 1 MB"
          density="compact"
          closable
          @click:close="SizeAlert=false"
        >
        </v-alert
      ></v-dialog>

      <v-btn class="mt-2 hidden-sm-and-up" block @click="sendfile()">
        hochladen
      </v-btn>

      <v-row class="ma-2">
        <v-alert
          v-if="ShowFalseInput"
          color="red"
          title="Falsches Format"
          icon="$warning"
          >Bitte eine Datei im folgenden Format auswählen:<br />
          <h3>PNG / PDF /JPG / JPEG</h3>
        </v-alert>
      </v-row>
    </v-card-text>
  </v-card>

  <v-dialog v-model="showErfolg">
    <v-card>
      <v-card-title class="HeaderTitle">
        <v-toolbar density="compact" color="primary">
          <v-toolbar-title>Dokument hochladen Erfolgreich </v-toolbar-title>
        </v-toolbar>
      </v-card-title>
    </v-card>
  </v-dialog>

  <v-dialog v-model="Fehler">
    <Error />
  </v-dialog>
</template>

<script setup>
import Error from "@/components/main/Errors.vue";
import dbhelper from "@/plugins/dbHelper";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();
const store = useStore();

var showErfolg = ref(false);
var ShowFalseInput = ref(false);
var Fehler = ref(false);
var SizeAlert = ref(false);

var Bezeichnung = ref("");
var rules = ref({
  required: (value) => !!value || "Pflichtfeld",
});

var DatenPacket = ref({
  Bezeichnung: "",
  Art: "",
  PDFFile: null,
});
var ArtItems = ref([
  "Vollmacht",
  "Gewerbenachweis/HR-Auszug",
  "Sonstige Dokument",
]);

onMounted(() => {
  DatenPacket.value.Art = "Vollmacht";
});

async function sendfile() {
  var url = "";
  var Bezeichnungausgefuelt = await Bezeichnung.value.validate();
  var size = DatenPacket.value.PDFFile.size;
  if (size >= store.state.MaxFileSize) {
   
    SizeAlert.value = true;
  } else {
    if (Bezeichnungausgefuelt.valid == true) {
      if (
        DatenPacket.value.PDFFile.type == "application/pdf" ||
        DatenPacket.value.PDFFile.type == "image/jpg" ||
        DatenPacket.value.PDFFile.type == "image/jpeg" ||
        DatenPacket.value.PDFFile.type == "image/png"
      ) {
        url = "uploadBilder";
      } else {
        ShowFalseInput.value = false;
      }

      var response = await dbhelper.sendDokument(url, DatenPacket.value);
      if (response.data.erfolgJN != "N") {
        router.go(window.location.origin + router.currentRoute);
      } else {
        if (response.data.fehlertext) {
          store.state.Login = false;
        } else {
          store.state.FehlerCode = 202;
          store.state.FehlerText = response.data.fehlertext;
          Fehler.value = true;
        }
      }
    }
  }
}
</script>

<style scoped>
.Optionen {
  margin: 2em;
}
</style>