<template>
  <v-card>
    <v-card-title class="HeaderTitle">
      <v-toolbar density="compact" color="primary">
        <v-toolbar-title> Diese Webseite verwendet Cookies </v-toolbar-title>
      </v-toolbar>
    </v-card-title>

    <v-card-text>
      <v-col>
        <v-row class="ma-2">
          Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren,
          Funktionen für soziale Medien anbieten zu können und die Zugriffe auf
          unsere Website zu analysieren. Außerdem geben wir Informationen zu
          Ihrer Verwendung unserer Website an unsere Partner für soziale Medien,
          Werbung und Analysen weiter. Unsere Partner führen diese Informationen
          möglicherweise mit weiteren Daten zusammen, die Sie ihnen
          bereitgestellt haben oder die sie im Rahmen Ihrer Nutzung der Dienste
          gesammelt haben.
        </v-row>
        <v-row class="ma-2">
          <v-btn class="mr-2" @click="aktzeptieren()"> Alle aktzeptieren</v-btn>
          <v-btn>Alle Ablehnen</v-btn>
        </v-row>
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

const emit = defineEmits(["closeCookieBanner"]);
const store = useStore();

function aktzeptieren() {
emit("closeCookieBanner")
}
function Ablehnen() {
    
}
</script>